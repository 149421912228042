import './wdyr.js'; // <--- first import per https://github.com/welldone-software/why-did-you-render

import LogRocket from 'shared/services/LogRocket';
import setupLogRocketReact from 'logrocket-react';
import hasOwn from 'object.hasown';
import AppConfig from 'shared/config-public';

import { createRoot } from 'react-dom/client';
import styled from 'styled-components';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import sayHello from 'utils/sayHello.js';
import localAppShim from 'localAppShim.js';
import reportWebVitals from './reportWebVitals';

// Older iPhones (<12) sometimes don't have Object.hasOwn
if (!Object.hasOwn) {
	hasOwn.shim();
}

sayHello();

if (AppConfig.buildEnv !== 'dev') {
	// Don't log in dev because it messes up console line numbers
	LogRocket.init(`xmpbov/vaya-app-v2`, {
		// Props available documented: https://docs.logrocket.com/v1.0/reference/init
		mergeIframes: true,
		release: AppConfig.version,
	});
	setupLogRocketReact(LogRocket);
}

/**
 * MAJOR MAJOR HACK
 * For whatever reason, styled-components don't work unless
 * we mount one FIRST here. THEN it will work elsewhere in the app.
 * Without a simple SC here, styled components just...don't...work. WHY?
 */
const StyledDiv = styled.div`
	background: transparent;
`;
const root = createRoot(document.getElementById('root'));
root.render(<StyledDiv />);

// // Hack done...now render the app like usual
// // ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<BootAnim />, document.getElementById('root'));

// // This actually boots App.js (or redirects to remote server)
// // depending on heuristics internally
// if (AppConfig.buildEnv !== 'dev') {
// 	localAppShim();
// } else {
// 	// In Dev, load app directly so we get better error messages
// 	import('./App.js').then(({ default: App }) =>
// 		ReactDOM.render(<App />, document.getElementById('root')),
// 	);
// }

localAppShim({ root });

// ref: https://capacitorjs.com/docs/web/pwa-elements
// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(AppConfig.buildEnv === 'prod' ? console.log : undefined);
