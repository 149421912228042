import { useEffect } from 'react';

/**
 * This component is based on the SimpleSpinner component (same SVG, same animation timing)
 * but all the styles are self-contained since it is rendered by index.js before App.js loads TW or anything else
 */

const stylesheet = `
	@keyframes boot-anim-spin {
		to {
			transform: rotate(360deg);
		}
	}

	.boot-anim-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		/* background: #eee; */
	}


	.boot-anim-spin-position {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.boot-anim-spinner {
		animation: boot-anim-spin 1s linear infinite;
		width: 8rem;
		height: 8rem;
		color: rgba(187, 83, 163, .5);
	}
`;

// I know it's generally bad form in react to modify the DOM,
// but I'm doing this here to prevent FOC on first boot.
// We still remove this from the DOM as soon as we unmount, so pretty safe.
const style = document.createElement('style');
style.id = 'boot-anim';
style.innerHTML = stylesheet;
const insertLocation = document.querySelector('script');
insertLocation.parentNode.insertBefore(style, insertLocation);

export function BootAnim() {
	useEffect(() => {
		return () => {
			if (insertLocation.parentNode) {
				insertLocation.parentNode.removeChild(style);
			}
		};
	});

	return (
		<div className="boot-anim-wrapper">
			<div className="boot-anim-spin-position">
				<svg
					className="boot-anim-spinner"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						strokeWidth="4"
					></circle>
					<path
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					></path>
				</svg>
			</div>
		</div>
	);
}
